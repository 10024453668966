import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/cuizizine/cuizizine/node_modules/@marinda/gatsby-theme-recipes/src/components/MarkdownPagesLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Cookies`}</h1>
    <p>
Nous ne récoltons pas de cookies, on préfère les manger 🍪
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      